<template>
  <div class="table-container">
    <!-- 搜索栏 -->
    <el-form :inline="true" class="search-form" @submit.native.prevent>
      <el-form-item>
        <el-input size="mini" v-model="searchQuery" placeholder="请输入搜索内容" @input="onSearch" clearable />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSearch" size="mini">搜索</el-button>
        <el-button @click="resetSearch" size="mini">重置</el-button>
      </el-form-item>
    </el-form>

    <!-- 表格 -->
    <el-table :data="filteredData" stripe border style="width: 100%" height="10%"
      @selection-change="handleSelectionChange">
      <el-table-column prop="name" label="名称" header-align="center" align="center" />
      <el-table-column prop="date" label="日期" header-align="center" align="center" />
      <el-table-column label="操作" header-align="center" align="center">
        <template #default="scope">
          <el-button size="mini" type="text" @click="editRow(scope.row)">
            编辑
          </el-button>
          <el-button size="mini" type="text" style="color: red" @click="confirmDeleteRow(scope.row)">
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <!-- 分页 -->
    <div class="pagination-container">
      <el-pagination background layout="prev, pager, next, sizes, total" :current-page.sync="currentPage"
        :page-size.sync="pageSize" :total="totalItems" @current-change="onPageChange" @size-change="onPageSizeChange" />
    </div>

    <!-- 编辑弹出框 -->
    <el-dialog title="编辑数据" :visible.sync="editDialogVisible" width="400px" @close="resetEditForm">
      <el-form :model="editForm" :rules="editFormRules" ref="editFormRef" label-width="80px">
        <el-form-item label="名称" prop="name">
          <el-input v-model="editForm.name" />
        </el-form-item>
        <el-form-item label="日期" prop="date">
          <el-date-picker v-model="editForm.date" type="date" placeholder="选择日期" style="width: 100%" clearable
            @change="handleDateChange" />
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button @click="editDialogVisible = false">取消</el-button>
        <el-button type="primary" @click="saveEdit">保存</el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { getlistMille } from "@/views/api/rolemiddle/index";
export default {
  name: "RoleMiddle",
  data() {
    return {
      searchQuery: "", // 搜索内容
      currentPage: 1, // 当前页
      pageSize: 10, // 每页大小
      totalItems: 0, // 总条目数
      tableData: [], // 表格数据
      filteredData: [], // 经过搜索和分页处理的数据
      editDialogVisible: false, // 编辑弹出框显示状态
      editForm: {}, // 当前编辑的数据
      editFormRules: {
        // 校验规则
        name: [
          { required: true, message: "请输入名称", trigger: "blur" },
          {
            min: 2,
            max: 20,
            message: "名称长度需在 2 到 20 个字符之间",
            trigger: "blur",
          },
        ],
        date: [{ required: true, message: "请选择日期", trigger: "change" }],
      },
    };
  },
  created() {
    this.fetchTableData();
  },
  methods: {
    // 模拟数据获取
    fetchTableData() {
      const data = { pageSize: this.pageSize, pageNum: this.currentPage }
      getlistMille(data).then((res) => {
        console.log(res, "8888")
      }).catch(() => {
      })
      const maxDay = 30; // 最大天数设为30
      this.tableData = Array.from({ length: 50 }, (_, i) => ({
        id: i + 1,
        name: `名称 ${i + 1}`,
        date: `2024-11-${String((i % maxDay) + 1).padStart(2, "0")}`, // 确保日期不超过30
      }));
      this.totalItems = this.tableData.length;
      this.updateFilteredData();
    },
    // 搜索处理
    onSearch() {
      this.currentPage = 1; // 搜索时重置为第一页
      this.updateFilteredData();
    },
    // 重置搜索
    resetSearch() {
      this.searchQuery = "";
      this.onSearch();
    },
    // 更新过滤后的数据
    updateFilteredData() {
      const filtered = this.tableData.filter((item) =>
        item.name.includes(this.searchQuery)
      );
      this.totalItems = filtered.length;
      const start = (this.currentPage - 1) * this.pageSize;
      const end = start + this.pageSize;
      this.filteredData = filtered.slice(start, end);
    },
    // 格式化日期
    formatDateToYMD(date) {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    },
    // 日期变化处理
    handleDateChange(date) {
      if (date) {
        this.editForm.date = this.formatDateToYMD(date);
        console.log("date:", this.editForm.date);
      } else {
        console.log("清空了日期");
      }
    },
    // 页码变化
    onPageChange(page) {
      this.currentPage = page;
      this.updateFilteredData();
    },
    onPageSizeChange(newSize) {
      this.pageSize = newSize;
      this.currentPage = 1; // 分页大小改变时回到第一页
      this.updateFilteredData();
    },
    // 编辑操作
    editRow(row) {
      console.log(row);
      this.editForm = { ...row }; // 克隆当前行数据
      this.editDialogVisible = true;
    },
    saveEdit() {
      this.$refs.editFormRef.validate((valid) => {
        if (valid) {
          // 校验通过后保存数据
          const index = this.tableData.findIndex(
            (item) => item.id === this.editForm.id
          );
          if (index !== -1) {
            this.tableData.splice(index, 1, { ...this.editForm }); // 更新表格数据
            this.updateFilteredData(); // 重新计算分页数据
          }
          console.log("修改的数据:", this.editForm);
          this.editDialogVisible = false; // 关闭弹出框
          this.$message.success("修改成功！");
        } else {
          this.$message.error("表单验证失败，请检查输入！");
        }
      });
    },
    resetEditForm() {
      this.editForm = {}; // 清空表单数据
    },
    // 删除确认
    confirmDeleteRow(row) {
      this.$confirm(`确定要删除 "${row.name}" 吗？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.deleteRow(row);
        })
        .catch(() => {
          this.$message.info("已取消删除");
        });
    },
    // 删除操作
    deleteRow(row) {
      this.tableData = this.tableData.filter((item) => item.id !== row.id);
      this.updateFilteredData();
      this.$message.success(`删除: ${row.name}`);
    },
    // 处理多选
    handleSelectionChange(selection) {
      console.log("选中项:", selection);
    },
  },
  mounted() {
    // 初始化表格数据
    this.fetchTableData();
  }
};
</script>

<style scoped>
.table-container {
  padding: 20px;
}

.pagination-container {
  display: flex;
  justify-content: flex-end;
  /* 将分页靠右对齐 */
  margin-top: 10px;
  /* 分页与表格间距 */
}
</style>