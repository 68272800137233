<template>
  <div id="app">
    <BindGroups />
    <RoleMiddle />
  </div>
</template>

<script>
// import BindGroups from './views/gruop/index.vue'
import RoleMiddle from './views/rolemiddle/index.vue'
export default {
  components: {
    // BindGroups,
    RoleMiddle
  },
  data() {
    return {}
  }
}
</script>

<style lang="less" scoped></style>
