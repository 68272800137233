import axios from 'axios';
import { Notification, Message } from 'element-ui';
import { tansParams } from "@/utils/ruoyi";

// 创建 axios 实例
const service = axios.create({
  baseURL: `http://spg.lvryk.com/sp`, // 基础请求地址
  timeout: 10000, // 超时时间
  headers: {
    'Content-Type': 'application/json;charset=utf-8',
  },
});

// 请求拦截器
service.interceptors.request.use(
  (config) => {
    // get 请求将参数转换为 URL 查询字符串
    if (config.method === 'get' && config.params) {
      let url = config.url + '?' + tansParams(config.params);
      url = url.slice(0, -1); // 移除末尾多余的 `&`
      config.params = {};
      config.url = url;
    }
    return config;
  },
  (error) => {
    console.error(error);
    return Promise.reject(error);
  }
);

// 响应拦截器
service.interceptors.response.use(
  (res) => {
    const code = res.data.code || 200; // 默认成功状态码为 200
    const msg = res.data.msg || '请求成功'; // 如果没有提供 `msg`，默认提示“请求成功”

    // 处理二进制数据
    if (res.request.responseType === 'blob' || res.request.responseType === 'arraybuffer') {
      return res.data;
    }

    if (code !== 200) {
      Notification.error({ title: msg });
      return Promise.reject(new Error(msg));
    }
    return res.data; // 成功返回数据
  },
  (error) => {
    let { message } = error;
    if (message === 'Network Error') {
      message = '后端接口连接异常';
    } else if (message.includes('timeout')) {
      message = '系统接口请求超时';
    } else if (message.includes('Request failed with status code')) {
      message = `系统接口 ${message.substr(message.length - 3)} 异常`;
    }
    Message({ message, type: 'error', duration: 5 * 1000 });
    return Promise.reject(error);
  }
);


export default service;